<template>
  <el-card class="box-card role full-screen">
    <div ref="pageHead" class="table-page-head">
      <el-row class="table-btns">
        <el-button type="text" icon="el-icon-edit" @click="editRole()" size="mini" class="text-btn">{{$t('addRole')}}</el-button>
      </el-row>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}" :fit="false">
      <el-table :data="roleList" v-loading="isloading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="selection" fixed="left" width="50"></el-table-column>
        <el-table-column prop="name" :label="$t('name')" width="160"></el-table-column>
        <el-table-column prop="type" :label="$t('type')" width="160">
          <!-- 1平台，2直营店，3加盟店，5个人客户 6合作商（公司） -->
          <template slot-scope="scope">
            <span v-if="scope.row.type==1">{{$t('platform')}}</span>
            <span v-else-if="scope.row.type==2">{{$t('directSaleStore')}}</span>
            <span v-else-if="scope.row.type==3">{{$t('franchiseStore')}}</span>
            <span v-else-if="scope.row.type==5">{{$t('individualAccount')}}</span>
            <span v-else-if="scope.row.type==6">{{$t('companyAccount')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('status')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="text-success">{{$t('normal')}}</span>
            <span v-else class="text-danger">{{$t('forbidden')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="resourceIds" :label="$t('resourceMenu')" min-width="200">
          <template slot-scope="scope">
            <div class='overstep-hidden' v-if="scope.row.resourceIds">
              <el-tag v-for="(t,i) in scope.row.resourceList||[]" :key="i" size="small">{{$t(t.name)}}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="businessTypeIds" :label="$t('businessMenu')" min-width="200">
          <template slot-scope="scope">
            <div class='overstep-hidden' v-if="scope.row.businessTypeIds">
              <el-tag v-for="(t,i) in scope.row.businessTypes||[]" :key="i" size="small">{{$t(t.name)}}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" :label="$t('remark')" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column fixed="right" :label="$t('extend')" width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="editRole(scope.row)" type="primary">{{$t('edit')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-drawer :title="$t('editRole')" :visible.sync="editRoleDrawer" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="700">
      <el-form :model="roleForm" :rules="rules" ref="editRole" label-position="top">
        <el-form-item :label="$t('name')" prop="name">
          <el-input size="medium" v-model="roleForm.name" />
        </el-form-item>
        <el-form-item v-if="roleForm.id" :label="$t('status')">
          <el-radio-group size="medium" v-model="roleForm.status">
            <el-radio :label="0">{{$t('normal')}}</el-radio>
            <el-radio :label="1">{{$t('forbidden')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('remark')">
          <el-input size="medium" v-model="roleForm.remark" />
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('resourceMenu')">
              <el-card shadow="never" body-style="height:280px;overflow:auto">
                <el-tree :data="resourceTree" :props="{label:resourceTreeLabelSet}" show-checkbox default-expand-all node-key="id" ref="resourceTree" highlight-current :default-checked-keys="roleForm.resourceIds" :check-strictly="checkStrictly" />
              </el-card>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('businessMenu')">
              <el-card shadow="never" body-style="height:280px;overflow:auto">
                <el-tree :data="businessTree" :props="{label:businessTreeLabelSet}" show-checkbox default-expand-all node-key="id" ref="businessTree" highlight-current :default-checked-keys="roleForm.businessTypeIds" :check-strictly="checkStrictly" />
              </el-card>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item class="bottom-button">
          <el-button v-if="roleForm.id" size="medium" type="primary" @click="updateSubmit('editRole')" :loading="isloading">
            {{$t('submit')}}
          </el-button>
          <el-button v-else size="medium" type="primary" @click="addSubmit('editRole')" :loading="isloading">{{$t('submit')}}</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </el-card>
</template>
<script>
import role from "@/api/role";
import business from "@/api/business";

export default {
  name: "roleManage",
  data () {
    return {
      queryForm: {},
      roleForm: { status: "1" },
      rules: { name: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }] },
      //table高度，动态设置
      queryTableHeight: "",
      //table显示内容
      roleList: [],
      resourceTree: [],
      businessTree: [],
      //显示加载中
      isloading: false,
      //新增\编辑角色
      editRoleDrawer: false,
      //设置角色权限
      editJurisdictionDrawer: false,
      //给tree赋值时不让他联动，解决设置父节点会把所有子节点一起勾选的问题
      checkStrictly: true
    };
  },
  computed: {},
  watch: {},
  methods: {
    /**获取角色列表 */
    getAllRole () {
      let _this = this;
      role.getAllRole({
        success: res => {
          if (res.code == 200)
            _this.roleList = res.data;
        }
      })
    },
    /**资源树label设置 */
    resourceTreeLabelSet (a) { return this.$t(a.name) },

    /**获取资源列表*/
    getAllResource (callback) {
      let _this = this;
      role.getAllResource({
        success: res => {
          if (res.code != 200) return;
          _this.resourceTree = res.data;
          callback ? callback() : '';
        }
      })
    },
    /**业务树label设置 */
    businessTreeLabelSet (a) {
      return this.$t(a.name) +
        (a.businessType ? `( ${a.businessType == 1 ? this.$t('internalDeal') : a.businessType == 2 ? this.$t('externalDeal') : ''})` : '');
    },
    //获取业务列表
    getBusinessList () {
      let _this = this;
      business.getList({
        success: res => {
          if (res.code != 200) return;
          let businessTree = res.data.filter(function (t) { return t.parentId == 0 });
          businessTree.forEach(t => {
            t.children = res.data.filter(function (t2) { return t2.parentId == t.id });
          });
          _this.businessTree = businessTree;
        }
      })
    },
    //其它操作
    editRole (data) {
      this.checkStrictly = true;
      this.$nextTick(() => {
        this.roleForm = { status: 1 };
        if (data) this.roleForm = data;
        if ((this.resourceTree || this.businessTree) && (this.resourceTree.length > 0 || this.businessTree.length > 0)) {
          this.editRoleDrawer = true;
          //给tree赋值时不让他联动，解决设置父节点会把所有子节点一起勾选的问题
          setTimeout(() => { this.checkStrictly = false; }, 500);
        }
        else {
          this.getAllResource(function () {
            this.checkStrictly = true;
            this.editRoleDrawer = true;
            //给tree赋值时不让他联动，解决设置父节点会把所有子节点一起勾选的问题
            setTimeout(() => { this.checkStrictly = false; }, 500);
          });
        }
      });
    },
    getCheckedKeys (ref) {
      // let keys = this.$refs[ref].getCheckedKeys();
      let nodes = this.$refs[ref].getCheckedNodes();
      let arr = new Array();
      nodes.forEach(function (t) {
        arr.push(t.id)
        if (t.pid && arr.indexOf(t.pid) == -1) arr.push(t.pid)
        else if (t.parentId && arr.indexOf(t.parentId) == -1) arr.push(t.parentId)
      })
      return arr;
    },
    addSubmit (formName) {
      let _this = this;

      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.isloading = true;
          let resourceIds = _this.getCheckedKeys('resourceTree');
          let businessTypeIds = _this.getCheckedKeys('businessTree');
          let aubmitForm = JSON.parse(JSON.stringify(_this.roleForm))

          aubmitForm.resourceIds = resourceIds.toString();
          aubmitForm.businessTypeIds = businessTypeIds.toString();

          role.add({
            param: aubmitForm,
            success: res => {
              if (res.code == 200) {
                _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
                _this.getAllRole();
                _this.editRoleDrawer = false;
              }
              else {
                _this.$message({ type: "warning", message: _this.$t(res.msg) });
              }
              _this.isloading = false;
            }
          })
        }
      })
    },
    updateSubmit (formName) {
      let _this = this;

      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.isloading = true;
          let resourceIds = _this.getCheckedKeys('resourceTree');
          let businessTypeIds = _this.getCheckedKeys('businessTree');
          let aubmitForm = JSON.parse(JSON.stringify(_this.roleForm))
          aubmitForm.resourceIds = resourceIds.toString();
          aubmitForm.businessTypeIds = businessTypeIds.toString();

          role.updateById({
            param: aubmitForm,
            success: res => {
              if (res.code == 200) {
                _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
                _this.getAllRole();
                _this.editRoleDrawer = false;
              }
              else {
                _this.$message({ type: "warning", message: _this.$t(res.msg) });
              }
              _this.isloading = false;
            }
          })
        }
      })
    },
  },
  mounted () {
    let _this = this;
    this.$nextTick(() => {
      let queryTableHeight = _this.$refs.pageHead.offsetHeight + 15;
      _this.queryTableHeight = queryTableHeight + 'px';
      _this.getAllRole();
      _this.getAllResource();
      _this.getBusinessList();
    })
  },
}
</script>
<style lang="less" scoped>
.tree-card {
  height: 300px;
}
</style>